/* eslint-disable react/no-array-index-key */
import React from 'react'

// Images
import Chevron from 'img/chevron.inline.svg'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay } from 'swiper'
import styled from 'styled-components'

import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Autoplay])

interface SliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_SliderLogos
}

const NextButton = styled.div`
  position: absolute;
  right: 0px;
  top: 30px;
  z-index: 10;
  cursor: pointer;

  & svg {
    width: 18px;
    height: 10px;
    transform: rotate(270deg);
  }
`

const PrevButton = styled.div`
  position: absolute;
  left: 0px;
  top: 30px;
  z-index: 10;
  cursor: pointer;

  & svg {
    width: 18px;
    height: 10px;
    transform: rotate(90deg);
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  object-fit: contain !important;
  width: 100%;

  & img {
    object-fit: contain !important;
  }
`

const Slider: React.FC<SliderProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 py-3">
      <Swiper
        spaceBetween={20}
        slidesPerView={3}
        navigation={{
          prevEl: '.slidePrev-btn',
          nextEl: '.slideNext-btn',
        }}
        breakpoints={{
          991: {
            spaceBetween: 20,
            slidesPerView: 7,
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 5,
          },
        }}
        loop
        autoplay={{
          delay: 5000,
        }}
      >
        <PrevButton className="slidePrev-btn">
          <Chevron />
        </PrevButton>
        {fields.logos?.map((logo, index) => (
          <SwiperSlide key={index}>
            <div className="d-flex position-relative justify-content-center">
              <StyledPlaatjie image={logo?.logo} alt="" />
            </div>
          </SwiperSlide>
        ))}
        <NextButton className="slideNext-btn">
          <Chevron />
        </NextButton>
      </Swiper>
    </div>
  </section>
)

export default Slider
